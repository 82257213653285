<template>
  <div>
    <section class="section is-main-section">
        <div class="columns" >
            <div class="column">
            <h2 class="title is-3">ປ່ຽນແປງປາຍທາງຂອງເຄື່ອງ</h2> 

     <div class="panel" style="padding: 1em 0">
        <b-field label="ຊອກຫາ" horizontal>
          <b-input
            v-model="search"
            placeholder="ຊອກຫາ"
            @keyup.native.enter="get()"
          />
          <p class="control" v-if="!isAndroid">
            <b-button
              icon-left="magnify"
              class="button is-primary"
              @click="get()"
            ></b-button>
          </p>
          <p class="control" v-if="isAndroid">
            <b-button
              icon-left="barcode"
              class="button is-primary"
              @click="scanBarcode"
            ></b-button>
          </p>
        </b-field>
      </div>
        </div>
        
        </div>

      <div class="columns" v-if="shipment">
        <div class="column">
          <h2 class="title is-5">ລາຍລະອຽດເຄື່ອງ ລະຫັດ - {{ code }} 
            <span v-if="shipment.shipment_hold === 1"
                  class="subtitle tag is-7 is-danger">
                ເຄື່ອງຖືກອາຢັດ: {{shipment.hold_reason}}
              </span>
            </h2> 
        </div>
        <div class="column">
          <div class="buttons is-pulled-right" 
          v-if="'arrived_destination_branch'.includes(shipment.status)">
            <section class="b-tooltips">
            <b-tooltip label="ສົ່ງເຄື່ອງກັບຄືນຕົ້ນທາງ" position="is-left" type="is-warning">
              <b-button label="ຕີກັບ"
                  class="is-warning button"
                  @click="rejectShipment(shipment.code)"
                  icon-left="undo-variant" />
              </b-tooltip>
              <b-tooltip label="ສົ່ງຕໍ່ເຄື່ອງໄປປາຍທາງໃໝ່" position="is-left" type="is-danger" v-if="'arrived_destination_branch'.includes(shipment.status)">
              <b-button label=" ສົ່ງຕໍ່"
                  class="is-danger button"
                  @click="forwardShipment(shipment.code)"
                  icon-left="share" />
             </b-tooltip>
            
             </section>
          </div>

        </div>
      </div>

      <hr />
      <div class="columns" v-if="shipment">
        <div class="column">
          <div class="panel is-primary ">
            <div class="panel-heading has-background-primary-light has-text-primary-dark">
              ຕົ້ນທາງ
              {{ shipment.from_branch ? shipment.from_branch.code : "" }} -
              {{ shipment.from_branch ? shipment.from_branch.name : "" }}
            </div>
            <div style="padding: 1em">
              <b-field label="ສາຂາ" horizontal>
                {{ shipment.from_branch ? shipment.from_branch.code : "" }} -
                {{ shipment.from_branch ? shipment.from_branch.name : "" }}
              </b-field>
              <b-field label="ເບີໂທຜູ້ສົ່ງ" horizontal>
                <router-link
                  v-if="shipment.customer_id != null"
                  :to="'/customer-info/' + shipment.customer_id"
                >
                  {{ shipment.sendertel }}
                </router-link>
                <span v-else>
                  {{ shipment.sendertel }}
                </span>
              </b-field>
              <b-field label="ຊື່ຜູ້ສົ່ງ" horizontal>
                {{ shipment.sendername }}
              </b-field>
              <span v-if="!shipment.is_receiver_pay" class="tag is-success">
                ຕົ້ນທາງຈ່າຍຄ່າສົ່ງແລ້ວ
              </span>
            </div>
          </div>

          <div class="title is-2">
            {{ shipmentStatuses[shipment.status].label }}
            <b-button @click="viewNewCode" v-if="'forwarded,return_to_source'.includes(shipment.status)" 
             type="is-ghost"
             icon-left="eye">
              ເບີ່ງລະຫັດໃໝ່ {{ shipment.remark.split(":")[shipment.remark.split(":").length-1] }}
             </b-button>
          </div>
        </div>

        <div class="column">
          <div class="panel is-success">
            <div class="panel-heading has-background-success-light has-text-success-dark">
              ປາຍທາງ {{ shipment.to_branch.code }} -
              {{ shipment.to_branch.name }}
            </div>
            <div style="padding: 1em">
              <b-field label="ສາຂາ" horizontal>
                {{ shipment.to_branch.code }} -
                {{ shipment.to_branch.name }}
              </b-field>
              <b-field label="ເບີໂທຜູ້ຮັບ" horizontal>
                {{ shipment.receivertel }}
              </b-field>
              <b-field label="ຊື່ຜູ້ຮັບ" horizontal>
                {{ shipment.receivername }}
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div
                v-if="!shipment.is_receiver_pay"
                class="title is-4 tag is-success"
              >
                ຈ່າຍແລ້ວ
              </div>
            </div>
            <div class="column">
              <div
                v-if="shipment.is_receiver_pay"
                class="title is-4 tag"
                :class="
                  shipment.status == 'delivered' ? 'is-success has-background-success-light has-text-success-dark' : 'is-danger has-background-danger-light has-text-danger'
                "
              >
                <span v-if="shipment.is_fee_in_cod" >ຫັກຄ່າສົ່ງຈາກ COD</span>
                <span v-if="!shipment.is_fee_in_cod">
                  ຄ່າຂົນສົ່ງເກັບນຳປາຍທາງ
                </span>
                <span v-if="shipment.status == 'delivered'"> - ຈ່າຍແລ້ວ </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns" v-if="shipment">
        <div class="column">
          <div v-if="shipment.parcel" class="panel is-warning ">
            <div class="panel-heading has-background-warning-light has-text-warning-dark">ເຄື່ອງຝາກ</div>
            <div class="table__wrapper">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>#</th>
                    <th class="has-text-centered">ປະເພດເຄື່ອງຝາກ</th>
                    <th class="has-text-centered">ຄວາມກວ້າງ (cm)</th>
                    <th class="has-text-centered">ຄວາມຍາວ (cm)</th>
                    <th class="has-text-centered">ຄວາມສູງ (cm)</th>
                    <th class="has-text-centered">ນໍ້າໜັກ (ກິໂລ)</th>
                    <th class="has-text-centered">ຈຳນວນ (ອັນ)</th>
                    <th class="has-text-right">ຄ່າຂົນສົ່ງ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(parcel, index) in shipment.parcel" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td class="has-text-centered">
                      {{ parcel.parceltype.name }} {{ parcel.remark }}
                    </td>
                    <td class="has-text-right">
                      {{ parcel.width }}
                    </td>
                    <td class="has-text-right">
                      {{ parcel.long }}
                    </td>
                    <td class="has-text-right">
                      {{ parcel.height }}
                    </td>
                    <td class="has-text-right">
                      {{ parcel.weight }}
                    </td>
                    <td class="has-text-right">x {{ parcel.qty }}</td>
                    <td class="has-text-right">
                      <div class="tags">
                        <span class="tag is-info" v-if="parcel.amount > 0">
                          {{ (parcel.amount * 1000) | formatnumber }} ກີບ
                        </span>
                        <span
                          class="tag is-primary"
                          v-if="parcel.amount_thb > 0"
                        >
                          {{ parcel.amount_thb | formatnumber }} ບາດ
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="2">ລວມ</th>
                    <th colspan="6" class="has-text-right">
                      <div v-if="shipment.amount > 0">
                        <u style="color: #ff0000; font-size: 1.5em">
                          {{ shipment.amount | formatnumber }} ກີບ
                        </u>
                      </div>
                      <div v-if="shipment.amount_thb > 0">
                        <u style="color: #ff0000; font-size: 1.5em">
                          {{ shipment.amount_thb | formatnumber }} ບາດ
                        </u>
                      </div>
                      <div
                        v-if="
                          !shipment.is_receiver_pay && shipment.fee_payment_mode
                        "
                      >
                        ວິທີຊຳລະ: {{ paymentMethod }}
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="columns"
        v-if=" shipment && shipment.is_cod && shipment.cod && shipment.cod.length > 0"
      >
        <div class="column is-3">
          <div class="panel is-info">
            <div class="panel-heading">COD</div>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th class="has-text-centered">ສະກຸນເງິນ</th>
                  <th class="has-text-centered">ຈຳນວນເງິນ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cod, index) in shipment.cod" :key="index">
                  <td class="has-text-centered">
                    <country-flag
                      :country="cod.ccy.substr(0, 2).toLowerCase()"
                    />
                    {{ cod.ccy }}
                  </td>
                  <td class="has-text-centered">
                    {{ cod.amount | formatnumber }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="columns" v-if="shipment">
        <div class="column">
          <div class="panel is-grey">
            <div class="panel-heading">ຕິດຕາມເຄື່ອງ (Tracking)</div>
            <div class="has-text-left">
              <TrackingComponent :items="trackings" />
            </div>
          </div>
        </div>
        <div class="column is-5" v-if="shipmentImages.length>0" >
          <div class="panel is-warning">
            <div class="panel-heading">ຮູບພາບເຄື່ອງ</div>
            <div class="has-text-centered  is-clipped">
              <b-carousel :autoplay="true" indicator-custom 
              :indicator-inside="false" 
              :overlay="gallery" @click="switchGallery(true)">
                <b-carousel-item v-for="(item, i) in shipmentImages" :key="i">
                    <a class="image ">
                        <b-image :src="item" :ratio="gallery?'1by1':'4by3'"></b-image>
                    </a>
                </b-carousel-item>
                <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large"/>
               <template #indicators="props">
                    <figure class="al image" :draggable="false">
                        <b-image :draggable="false" :src="getImageName(props.i)" :title="props.i" ratio="4by3"></b-image>
                    </figure>
                </template>
            </b-carousel>
            </div>
          </div>
        </div>
      </div>

    </section>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { get } from "vuex-pathify";
import TrackingComponent from "../components/TrackingComponent.vue";

export default {
  name: "RejectShipment",
  components: {
    TrackingComponent,
  },
  computed: {
    user: get("user"),
    shipmentStatuses: get("shipmentStatuses"),
    paymentmethods: get("paymentmethods"),
    exchangerate: get("exchangerate"),
    paymentMethod() {
      if (this.shipment) {
        let t = this.paymentmethods.filter(
          (i) => i.id === this.shipment.fee_payment_mode.toLowerCase()
        );
        if (t.length > 0) {
          return t[0].name;
        }
      }

      return "ບໍ່ໄດ້ເລືອກ";
    },
  },
  
  data() {
    return {
      ccy: "LAK",
      ccys: [{ code: "LAK" }, { code: "THB" }],
      code: null,
      search:'',
      shipment: null,
      isShowModalDelivery: false,
      amount: null,
      trackings: null,
      activeTab: 0,
      isLoading: false,
      shipid:0,
      shipmentImages:[],
      gallery: false
    };
  },
  watch: {
    shipment: function(){
      console.log('Shipment changed.');
      axios.get("shipments/images/" + this.shipment.id).then((r) => {
        this.shipmentImages=r;
      });
    }

  },
  methods: {
      viewNewCode(){
      const newCode=this.shipment.remark.split(":")[this.shipment.remark.split(":").length-1];
      this.$router.push({ path: "/shipment/" + newCode });
    },
    getImageName(index){
      return this.shipmentImages[index];
    },
    switchGallery(value) {
            this.gallery = value
            if (value) {
                return document.documentElement.classList.add('is-clipped')
            } else {
                return document.documentElement.classList.remove('is-clipped')
            }
      },

    getChanges() {
      const c = this.sumLak - this.getTotalShipment("LAK");
      return c;
    },
    getCod(ccy) {
      const cod = this._.find(this.shipment.cod, (c) => c.ccy == ccy);
      return cod ? cod.amount : 0;
    },
    getTrackings() {
      axios.get("track/" + this.shipment.code).then((r) => {
        if (r && r.trackings) {
          this._.map(r.trackings, (i) => {
            i.tag = i.date;
            i.htmlMode = true;
            i.type = "circle";
            i.color = "green";
            i.content = `
                <div>
                  <div style='font-size: 1.5em'>${i.title}</div>
                  <div style='font-size: 1em;color: #777777'>${i.description}</div>
                </div>
                `;
            this.trackings = r.trackings;
          });
        }
      });
    },
    getTotalShipment(ccy) {
      var sum = { LAK: 0, THB: 0 };
      if (this.shipment) {
        if (this.shipment.is_receiver_pay && !this.shipment.is_fee_in_cod) {
          sum.LAK += this.shipment.amount;
          sum.THB += this.shipment.amount_thb;
        }
        if (this.shipment.is_cod && this.shipment.cod) {
          for (let index = 0; index < this.shipment.cod.length; index++) {
            const element = this.shipment.cod[index];
            sum[element.ccy] += element.amount;
          }
        }
      }
      return sum[ccy];
    },
    get() {
        if(!this.search || this.search.trim()==='') return;
      axios
        .get("shipments/get-for-reject-or-forward/" + this.search)
        .then((r) => {
          //console.log(r);
          this.shipment = r;
          this.code=this.shipment.code;
          this.shipment.from_branch = r.branch_branchToshipment_from_branch_id;
          this.shipment.to_branch = r.branch_branchToshipment_to_branch_id;
          this.getTrackings();
          
          this.$forceUpdate();
        })
        .catch((e) => {
          console.log(e);
          Swal.fire("ບໍ່ພົບ", e, "info");
        });
    },

    rejectShipment(code){
      this.$router.push({ path: `/updateshipment/${code}` , query: { command:'REJECT' } });
    },
    forwardShipment(code){
      this.$router.push({ path: `/updateshipment/${code}` , query: { command:'FORWARD' }});
    },
  },
};
</script>
<style lang="scss" scoped>
  .table__wrapper {
    overflow-x: auto;
  }

  .is-active .al img {
      border: 1px solid #fff;
      filter: grayscale(0%);
  }
  .al img {
      border: 1px solid transparent;
      filter: grayscale(100%);
  }

  .b-tooltips {
      .b-tooltip:not(:last-child) {
          margin-right: .5em
      }
      .b-tooltip {
          margin-bottom: .5em
      }
  }
</style>
